import { connect } from 'react-redux';

import { ProductListWidgetContainer } from 'Component/ProductListWidget/ProductListWidget.container';

import ProductListWidgetWithoutMobile from './ProductListWidgetWithoutMobile.component';

/** @namespace Pwa/Component/ProductListWidgetWithoutMobile/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});
/** @namespace Pwa/Component/ProductListWidgetWithoutMobile/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductListWidgetWithoutMobile/Container/ProductListWidgetWithoutMobileContainer */
export class ProductListWidgetWithoutMobileContainer extends ProductListWidgetContainer {
    render() {
        return <ProductListWidgetWithoutMobile {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListWidgetWithoutMobileContainer);
