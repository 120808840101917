import React, { Component } from 'react';

import ProductList from 'Component/ProductList';

/** @namespace Pwa/Component/ProductListWidgetWithoutMobile/Component/ProductListWidgetWithoutMobile */
export class ProductListWidgetWithoutMobile extends Component {
    render() {
        const {
            device: { isMobile },
        } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <ProductList
                {...this.props}
                {...this.containerFunctions}
                isInfiniteLoaderEnabled={false}
                numberOfPlaceholders={6}
                mix={{ block: 'ProductListWidget' }}
                isWidget
            />
        );
    }
}

export default ProductListWidgetWithoutMobile;
